import * as Yup from 'yup';

export const FORM_FIELDS = {
    bonusName: {
        name: 'name',
        label: 'Name the bonus',
        placeholder: 'Enter a name for this bonus',
    },
    bonusRecipients: {
        name: 'bonusRecipients',
        label: 'Who do you want to send bonus to:',
        placeholder: 'Type or select someone',
    },
    bonusAmount: {
        name: 'bonusAmount',
        label: 'Bonus Amount:',
        placeholder: 'Enter the amount you would like to award as bonus',
        type: 'number',
    },
    bonusMessage: {
        name: 'bonusMessage',
        label: 'Message',
        placeholder: 'Write a message to go along with the bonus',
    },
    companyValue: {
        name: 'companyValue',
        label: 'Company value highlighted',
        placeholder: 'Select',
    },
    bonusChannel: {
        name: 'bonusChannel',
        label: 'Where do you want to post the message?',
        placeholder: 'Type or select channel',
    },
    scheduledDate: {
        name: 'scheduledDate',
        label: 'Date',
        placeholder: 'Select a date',
        optional: false,
    },
    scheduledTime: {
        name: 'scheduledTime',
        label: 'Time',
        placeholder: 'Select a time',
    },
};

export const initialValues = {
    name: '',
    bonusRecipients: '',
    bonusAmount: '',
    bonusMessage: '',
    bonusChannel: '',
    scheduledDate: '',
    scheduledTime: '',
    companyValueId: '',
    sendInstantly: false,
};

export const getValidationSchema = () =>
    Yup.object().shape({
        bonusName: Yup.string().max(60, 'Bonus Name should be less than 60 characters.'),
        bonusRecipients: Yup.object().required('Please complete this required field.'),
        bonusAmount: Yup.number()
            .min(1, 'Invalid value; enter value greater than 0 to proceed.')
            .max(999999, 'Bonus amount must not exceed 999999')
            .required('Bonus amount is required'),
        bonusMessage: Yup.string()
            .required('Bonus message is required')
            .min(10, 'You must enter atleast 10 characters')
            .max(3000, 'Bonus message must be less than or equal to 3000 characters'),
        bonusChannel: Yup.object().required('Bonus channel is required'),
        scheduledDate: Yup.string().required('Scheduled date is required'),
        scheduledTime: Yup.object().required('Scheduled time is required'),
    });
