import React, { useState, useEffect } from 'react';
import { dialog } from '@microsoft/teams-js';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import { CustomInputField } from 'components/CustomInputField';
import { CustomTextArea } from 'components/CustomTextArea';
import { CustomCalendar } from 'components/CustomCalender';
import { getValidationSchema, FORM_FIELDS, initialValues } from './constants';
import { useBonusData } from './useBonusData';
import { Body1Stronger, Caption1 } from '@fluentui/react-components';
import { timeInterval, getLaunchDate, addSecondsToCurrentDate } from 'utils/helperFunctions';
import { Formik, Form } from 'formik';
import { EWButton } from 'components/EWButton';
import AIEnhanceButton from 'components/AIEnhanceButton';
import PropTypes from 'prop-types';

const BonusForm = ({ authToken, setSubmitLoader }) => {
    const { members, channels, companyValues, isAiEnabled } = useBonusData(authToken);
    const [timeOptions, setTimeOptions] = useState(timeInterval(new Date()));
    const getBonusAmountLabel = (selectedUser) => {
        if (selectedUser) {
            return `Bonus Amount in ${selectedUser?.currency}:`;
        } else {
            return 'Bonus Amount:';
        }
    };

    const handleSubmit = (values) => {
        setSubmitLoader(true);
        let sendInstantly = false;
        let scheduledTime = values.scheduledTime.header;
        if (values.scheduledTime.header === 'Send instantly') {
            scheduledTime = addSecondsToCurrentDate(15);
            sendInstantly = true;
        }
        const { launchDate } = getLaunchDate(values.scheduledDate, scheduledTime, sendInstantly);
        const data = {
            name: values.name || 'Bonus',
            amount: values.bonusAmount,
            selectedUsers: [values.bonusRecipients.id],
            message: values.bonusMessage,
            channel: values.bonusChannel,
            currency: values.bonusRecipients.currency,
            launchDate: launchDate,
            sendInstantly: sendInstantly,
            companyValueId: values.companyValue?.id,
        };
        if (values.bonusChannel?.id) {
            data.channel = values.bonusChannel.id;
        }

        dialog.url.submit(
            { data, startedVia: 'EW Shortcuts messaging extension', flagType: 'bonus_submit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => {
                useEffect(() => {
                    setFieldValue(FORM_FIELDS.scheduledTime.name, ''); // Reset dropdown when options change i.e when date is changed
                }, [timeOptions, setFieldValue]);

                return (
                    <Form>
                        <CustomTextArea
                            {...FORM_FIELDS.bonusName}
                            label={
                                <>
                                    Name the bonus <Caption1>(Optional)</Caption1>
                                </>
                            }
                            height={'30px'}
                            maxLength={60}
                        />
                        <CustomSingleSelectDropdown
                            {...FORM_FIELDS.bonusRecipients}
                            isDropdownOptionLoading={members.isFetching}
                            dropdownOption={members.data}
                        />
                        <CustomInputField
                            {...FORM_FIELDS.bonusAmount}
                            label={getBonusAmountLabel(values[FORM_FIELDS.bonusRecipients.name])}
                        />

                        <CustomTextArea
                            {...FORM_FIELDS.bonusMessage}
                            label={
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignContent: 'flex-end',
                                    }}
                                >
                                    {FORM_FIELDS.bonusMessage.label}
                                    {isAiEnabled && (
                                        <AIEnhanceButton
                                            reason={values.bonusMessage}
                                            setReason={(reason) => setFieldValue('bonusMessage', reason)}
                                            authToken={authToken}
                                        />
                                    )}
                                </div>
                            }
                        />
                        {companyValues.data?.length > 0 && (
                            <CustomSingleSelectDropdown
                                {...FORM_FIELDS.companyValue}
                                label={
                                    <>
                                        Company Value <Caption1>(Optional)</Caption1>
                                    </>
                                }
                                isDropdownOptionLoading={companyValues.isFetching}
                                dropdownOption={companyValues.data}
                            />
                        )}
                        <CustomSingleSelectDropdown
                            {...FORM_FIELDS.bonusChannel}
                            isDropdownOptionLoading={channels.isFetching}
                            dropdownOption={channels.data}
                        />
                        <div className='body-2' style={{ marginTop: '-5px', marginBottom: '10px' }}>
                            <Caption1> Bonus message can be sent privately or to a public channel. </Caption1>
                        </div>

                        <div>
                            <Body1Stronger>When do you want to announce this Bonus?</Body1Stronger>
                            <CustomCalendar
                                {...FORM_FIELDS.scheduledDate}
                                minDate={new Date()}
                                onDateChange={timeInterval}
                                setTimeOptions={setTimeOptions}
                            />
                            <CustomSingleSelectDropdown {...FORM_FIELDS.scheduledTime} dropdownOption={timeOptions} />
                            <div className='body-2' style={{ marginTop: '-5px', marginBottom: '10px' }}>
                                <Caption1> Bonus will be announced as per the organization timezone. </Caption1>
                            </div>
                        </div>

                        <EWButton appearance='primary' buttonText='Submit' />
                    </Form>
                );
            }}
        </Formik>
    );
};
BonusForm.propTypes = {
    authToken: PropTypes.string,
    setSubmitLoader: PropTypes.func,
};

export default BonusForm;
