import React from 'react';
import { Field } from 'formik';
import { Field as FluentField, Input, makeStyles, typographyStyles } from '@fluentui/react-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: typographyStyles.subtitle2,
});

export const CustomInputField = ({ label, placeholder, name, type = 'text', render = true }) => {
    const styles = useStyles();

    if (!render) {
        return null;
    }
    return (
        <Field name={name}>
            {({ form: { setFieldValue, touched, errors } }) => {
                const onChangeHandler = (e) => {
                    setFieldValue(name, e.target.value);
                };
                return (
                    <FluentField
                        className={styles.text}
                        label={label}
                        validationMessage={touched[name] && errors[name]}
                        style={{ marginBottom: '10px' }}
                    >
                        <Input onChange={onChangeHandler} placeholder={placeholder} type={type} />
                    </FluentField>
                );
            }}
        </Field>
    );
};

CustomInputField.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    render: PropTypes.bool,
};
