export const QUESTION_TYPES = {
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    SHORT_TEXT: 'SHORT_TEXT',
    LONG_TEXT: 'LONG_TEXT',
    BINARY: 'BINARY',
    RATING: 'RATING',
    RATING_5: 'RATING_5',
    LIKERT: 'LIKERT',
};
