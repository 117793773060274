import React from 'react';
import PropTypes from 'prop-types';
import { CustomMultiSelectDropdown } from 'components/CustomMultiSelectDropdown';
import { CustomTextArea } from 'components/CustomTextArea';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import { QUESTION_TYPES } from './constants';

const QUESTION_OPTIONS = {
    BINARY: ['Yes', 'No'],
    RATING: Array(10)
        .fill()
        .map((_, i) => `${i + 1}`),
    RATING_5: Array(5)
        .fill()
        .map((_, i) => `${i + 1}`),
    LIKERT: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
};

const renderInput = (question) => {
    const { _id, type, options } = question;

    const optionList =
        (QUESTION_OPTIONS[type] || options)?.map((op, index) => ({ header: op, value: op, id: index })) || [];

    switch (type) {
        case QUESTION_TYPES.SINGLE_CHOICE:
        case QUESTION_TYPES.BINARY:
        case QUESTION_TYPES.RATING:
        case QUESTION_TYPES.RATING_5:
        case QUESTION_TYPES.LIKERT:
            return <CustomSingleSelectDropdown name={_id} dropdownOption={optionList} placeholder='Select an option' />;

        case QUESTION_TYPES.MULTIPLE_CHOICE:
            return (
                <CustomMultiSelectDropdown
                    name={_id}
                    dropdownOption={optionList}
                    placeholder='Select at least one option'
                />
            );
        case QUESTION_TYPES.SHORT_TEXT:
        case QUESTION_TYPES.LONG_TEXT:
            return <CustomTextArea name={_id} maxLength={type === QUESTION_TYPES.SHORT_TEXT ? 255 : 2500} />;

        default:
            return null;
    }
};

const QuestionComponent = ({ question, questionNumber }) => {
    return (
        <div className='question-container'>
            <h4>
                {`Q${questionNumber}: ${question.title}`}
                {!question.required && <span> (Optional)</span>}
            </h4>
            <div>{renderInput(question)}</div>
        </div>
    );
};

QuestionComponent.propTypes = {
    question: PropTypes.object,
    questionNumber: PropTypes.number,
};

export default QuestionComponent;
