import React, { useState } from 'react';
import {
    Field as FluentField,
    Tag,
    TagPicker,
    TagPickerList,
    TagPickerInput,
    TagPickerControl,
    TagPickerOption,
    TagPickerGroup,
    Tooltip,
    Spinner,
    typographyStyles,
    makeStyles,
} from '@fluentui/react-components';
import { InfoFilled } from '@fluentui/react-icons';
import { Field } from 'formik';
import './style.scss';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: typographyStyles.subtitle2,
    scrollbar: {
        maxHeight: '50vh',
    },
});

export const CustomMultiSelectDropdown = ({ label, placeholder, dropdownOption, isDropdownOptionLoading, name }) => {
    const [query, setQuery] = useState('');
    const styles = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const onOpenChange = (e, open) => {
        if (open.type === 'blur') {
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen(open);
        }
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue, setFieldTouched, touched, errors } }) => {
                // Check if dropdownOption is defined and is an array before filtering
                const tagPickerOptions =
                    dropdownOption && Array.isArray(dropdownOption)
                        ? dropdownOption.filter((option) => {
                              const selectedOptionsIds = new Set(value.map((item) => item.header || item));
                              return !selectedOptionsIds.has(option.header || item);
                          })
                        : [];

                const onOptionSelect = (e, data) => {
                    if (!data.value.disabled) {
                        setFieldValue(name, data.selectedOptions);
                    }
                    setQuery('');
                    setIsDropdownOpen(true);
                };

                // Filter and render the options
                const filter = (options, query) => {
                    return options.filter((option) => option.header.toLowerCase().includes(query.toLowerCase()));
                };

                const renderOption = (option) => (
                    <TagPickerOption value={option} key={option.id}>
                        {option.disabled ? (
                            <div className='disabled-option'>
                                {`${option.header} `}
                                <span>{'(Restricted)'}</span>
                                <Tooltip content={option.disabledNote}>
                                    <InfoFilled style={{ paddingLeft: '5px' }} />
                                </Tooltip>
                            </div>
                        ) : (
                            <span>{option.header}</span>
                        )}
                    </TagPickerOption>
                );

                return (
                    <FluentField
                        label={label}
                        className={styles.text}
                        style={{ maxWidth: 800, marginBottom: '10px' }}
                        validationMessage={touched[name] && errors[name]}
                    >
                        <TagPicker
                            onOpenChange={onOpenChange}
                            open={isDropdownOpen}
                            onOptionSelect={onOptionSelect}
                            selectedOptions={value}
                        >
                            <TagPickerControl>
                                <TagPickerGroup>
                                    {value.map((option) => (
                                        <Tag key={option.id} shape='rounded' value={option}>
                                            {option.header || option}
                                        </Tag>
                                    ))}
                                </TagPickerGroup>
                                <TagPickerInput
                                    placeholder={value.length ? '' : placeholder}
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    onBlur={() => setFieldTouched(name, true)}
                                />
                            </TagPickerControl>

                            <TagPickerList className={styles.scrollbar}>
                                {isDropdownOptionLoading ? (
                                    <Spinner />
                                ) : (
                                    filter(tagPickerOptions, query).map(renderOption)
                                )}
                            </TagPickerList>
                        </TagPicker>
                    </FluentField>
                );
            }}
        </Field>
    );
};

CustomMultiSelectDropdown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    dropdownOption: PropTypes.array,
    isDropdownOptionLoading: PropTypes.bool,
};
