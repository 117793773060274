import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/images/back-icon.svg';
import CustomLoader from 'components/CustomOverlay';
import SuggestionBoxForm from './SuggestionBoxForm';

const Index = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isTab = location.state?.token;
    const token = searchParams.get('token') || location.state?.token;
    const [submitLoader, setSubmitLoader] = useState(false);
    return (
        <CustomLoader active={submitLoader}>
            <div className='suggestion-box-container'>
                {isTab && (
                    <div className='back-navigation'>
                        <span onClick={() => navigate(-1)}>
                            <BackIcon /> Back
                        </span>
                    </div>
                )}
                <div className='headline-2'>Suggestion Box</div>
                <SuggestionBoxForm authToken={token} setSubmitLoader={setSubmitLoader} />
            </div>
        </CustomLoader>
    );
};

export default Index;
