import { useState } from 'react';
import { useQuery } from 'react-query';
import { getSuggestionBoxData } from 'Services/apiFunctions';
import { GET_SUGGESTION_BOX_DATA } from 'Services/apiKeys';
import { useNavigate } from 'react-router-dom';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';
import { getTeamsChannelName } from 'utils/helperFunctions';

export const useSuggestionBoxData = (authToken) => {
    const navigate = useNavigate();
    const { setSnackbar } = useToaster();
    const [allowAnonymous, setAllowAnonymous] = useState(false);
    const [postPublicly, setPostPublicly] = useState(false);
    const [suggestionChannel, setSuggestionChannel] = useState('');
    const [isAiEnabled, setIsAiEnabled] = useState(false);

    useQuery([GET_SUGGESTION_BOX_DATA, authToken], getSuggestionBoxData, {
        onError: (err) => {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
            navigate('/modals/inactive');
        },
        onSuccess: (data) => {
            let suggestionBoxData = data.data;
            setAllowAnonymous(suggestionBoxData?.allowAnonymous);
            setPostPublicly(suggestionBoxData?.postPublicly);
            setSuggestionChannel(getTeamsChannelName(suggestionBoxData?.suggestionChannel, true));
            setIsAiEnabled(suggestionBoxData.isAiEnabled);
        },
    });

    return { allowAnonymous, postPublicly, suggestionChannel, isAiEnabled };
};
