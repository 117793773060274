import { useState } from 'react';
import { useQuery } from 'react-query';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { getPulseData } from 'Services/apiFunctions';
import { GET_PULSE_DATA } from 'Services/apiKeys';

export const usePulseData = (authToken, responseId) => {
    const navigate = useNavigate();
    const { setSnackbar } = useToaster();
    const [data, setAnswerData] = useState({ data: {}, isFetching: true });
    useQuery([GET_PULSE_DATA, authToken, responseId], getPulseData, {
        onError: (err) => {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
            navigate('/modals/inactive');
        },
        onSuccess: ({ data }) => {
            setAnswerData({ data, isFetching: false });
        },
    });

    return { ...data.data, isLoading: data.isFetching };
};
