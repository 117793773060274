import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { CustomMultiSelectDropdown } from 'components/CustomMultiSelectDropdown';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import { CustomTextArea } from 'components/CustomTextArea';
import { EWButton } from 'components/EWButton';
import { getValidationSchema, FORM_FIELDS } from './constant';
import PropTypes from 'prop-types';
import { Caption1, Caption1Stronger } from '@fluentui/react-components';
import { dialog } from '@microsoft/teams-js';
import { getGifList } from 'Services/apiFunctions';
import { GET_GIF_LIST } from 'Services/apiKeys';
import { useRecognitionData } from './useRecognitionData';
import AIEnhanceButton from 'components/AIEnhanceButton';
import { getTeamsChannelName } from 'utils/helperFunctions';
import SelectGifSection from 'components/SelectGifSection';

const RecognitionForm = ({
    authToken,
    creditBalance,
    setCreditBalance,
    setSubmitLoader,
    messageId,
    selectedGif,
    setSelectedGif,
    isAddOn,
}) => {
    const {
        coreValues,
        rewards,
        members,
        channel,
        initialValues,
        isCompanyValueRequired,
        shouldShowRecognitionChannel,
        currentUserId,
        defaultRecognitionChannel,
        isAiEnabled,
    } = useRecognitionData(authToken, messageId, setCreditBalance);

    const [images, setImages] = useState([]); //GIFs based on the recognition type
    const [recognitionType, setRecognitionType] = useState(initialValues.recognitionType);

    // Fetch GIFs when recognitionType changes
    useEffect(() => {
        if (recognitionType) {
            const rewardType = recognitionType?.header?.toLowerCase().includes('shoutout') ? 'shoutout' : 'kudos';
            getGifList({ queryKey: [GET_GIF_LIST, authToken, rewardType] })
                .then((data) => {
                    setImages(data);
                })
                .catch(() => {
                    setImages([]);
                });
        }
    }, [recognitionType]);

    const handleSubmit = (values) => {
        let data = {
            reward: values.recognitionType.id,
            companyValues: values.companyValue.map((value) => value.id),
            reason: values.recognitionReason,
            users: values.recognitionRecipients.map((item) => item.id),
            messageId,
            gifUrl: selectedGif,
            ...(!messageId && { channel: values.recognitionChannel }),
        };
        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'messagingExtension', flagType: 'recognitions_submit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(isCompanyValueRequired, messageId, currentUserId, creditBalance)}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <CustomSingleSelectDropdown
                        {...FORM_FIELDS.recognitionType}
                        isDropdownOptionLoading={rewards.isFetching}
                        dropdownOption={rewards.data}
                        onChange={(value) => setRecognitionType(value)}
                    />

                    <CustomMultiSelectDropdown
                        {...FORM_FIELDS.recognitionRecipients}
                        isDropdownOptionLoading={members.isFetching}
                        dropdownOption={members.data}
                    />
                    <CustomTextArea
                        {...FORM_FIELDS.recognitionReason}
                        label={
                            <div className='enhance_ai_btn'>
                                <span>
                                    Why do you want to recognize?{' '}
                                    {messageId && <span className='optional-label'>(Optional)</span>}
                                </span>

                                {isAiEnabled && (
                                    <AIEnhanceButton
                                        reason={values.recognitionReason}
                                        setReason={(reason) => setFieldValue('recognitionReason', reason)}
                                        authToken={authToken}
                                    />
                                )}
                            </div>
                        }
                    />
                    <CustomMultiSelectDropdown
                        {...FORM_FIELDS.companyValue}
                        label={
                            <>
                                Company Value{' '}
                                {isCompanyValueRequired ? '' : <span className='optional-label'>(Optional)</span>}
                            </>
                        }
                        isDropdownOptionLoading={coreValues.isFetching}
                        dropdownOption={coreValues.data}
                        render={coreValues.data.length > 0}
                    />
                    <CustomSingleSelectDropdown
                        {...FORM_FIELDS.recognitionChannel}
                        isDropdownOptionLoading={channel.isFetching}
                        dropdownOption={channel.data}
                        render={shouldShowRecognitionChannel}
                    />

                    {defaultRecognitionChannel ? (
                        <div className='body-2'>
                            <Caption1Stronger>Note:</Caption1Stronger>
                            <Caption1> Recognition will be sent out in </Caption1>
                            <Caption1Stronger>{getTeamsChannelName(defaultRecognitionChannel)}</Caption1Stronger>
                        </div>
                    ) : null}

                    {!isAddOn && (
                        <SelectGifSection
                            images={images}
                            selectedImage={selectedGif}
                            setSelectedImage={setSelectedGif}
                        />
                    )}

                    <EWButton appearance='primary' buttonText='Submit' />
                </Form>
            )}
        </Formik>
    );
};

RecognitionForm.propTypes = {
    authToken: PropTypes.string,
    setCreditBalance: PropTypes.func,
    setSubmitLoader: PropTypes.func,
    messageId: PropTypes.string,
    creditBalance: PropTypes.number,
    selectedGif: PropTypes.string.isRequired,
    setSelectedGif: PropTypes.func.isRequired,
    isAddOn: PropTypes.bool.isRequired,
};

export default RecognitionForm;
