import React, { useState } from 'react';
import { Field } from 'formik';
import { Field as FluentField, Textarea, typographyStyles, makeStyles, shorthands } from '@fluentui/react-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: typographyStyles.subtitle2,
    container: {
        position: 'relative',
        marginBottom: '10px',
    },
    textarea: {
        width: '100%',
        paddingRight: '40px',
    },
    charCounter: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: '2px 5px',
        fontSize: '12px',
        color: 'gray',
        pointerEvents: 'none',
        ...shorthands.borderRadius('4px'),
    },
});

export const RESIZE_OPTIONS = {
    NONE: 'none',
    BOTH: 'both',
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export const CustomTextArea = ({ name, label, placeholder, maxLength, height, resize }) => {
    const styles = useStyles();
    const [remainingChars, setRemainingChars] = useState(maxLength || 0);

    return (
        <Field name={name} size='large'>
            {({ field, form: { setFieldValue, setFieldTouched, errors, touched } }) => {
                const onChange = (ev) => {
                    const { value } = ev.target;
                    setFieldValue(name, value);
                    setRemainingChars(maxLength - value.length); // Update remaining characters
                };

                return (
                    <FluentField
                        label={label}
                        className={styles.text}
                        validationMessage={touched[name] && errors[name]}
                    >
                        <div className={styles.container}>
                            <Textarea
                                value={field.value}
                                onChange={onChange}
                                appearance='outline'
                                style={{ width: '100%', height: height }}
                                onBlur={() => {
                                    if (field.value?.length > 0) {
                                        return;
                                    }
                                    setFieldTouched(name, true);
                                }}
                                placeholder={placeholder}
                                resize={resize}
                                maxLength={maxLength}
                                className={styles.textarea}
                            />
                            {maxLength && <span className={styles.charCounter}>{remainingChars}</span>}
                        </div>
                    </FluentField>
                );
            }}
        </Field>
    );
};

CustomTextArea.defaultProps = {
    height: 'auto',
    resize: RESIZE_OPTIONS.VERTICAL,
};

CustomTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    height: PropTypes.string,
    resize: PropTypes.oneOf(Object.values(RESIZE_OPTIONS)),
};
