import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { app } from '@microsoft/teams-js';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RewardsAndRecognitionIllustration } from 'assets/images/rewards-and-recognitions.svg';
import { ReactComponent as RecoginzeIllustration } from 'assets/images/recognize-someone.svg';
import { ReactComponent as RedeemIllustration } from 'assets/images/redeem-in-web.svg';
import { ReactComponent as BonusIllustration } from 'assets/images/award-bonus-icon.svg';
import { ReactComponent as SuggestionBoxIllustration } from 'assets/images/suggestion-box.svg';
import { ReactComponent as EmployeeAwardsIllustration } from 'assets/images/employee-awards-icon.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link-icon.svg';
import { useCommonContext } from 'context';
import {
    AWARD_BONUS,
    AWARD_BONUS_SUBTEXT,
    RECOGNIZE_SOMEONE_SUBTEXT,
    GO_TO_SETTINGS,
    RECOGNITION_SETUP_DONE_ADMIN,
    RECOGNITION_SETUP_DONE_USER,
    RECOGNIZE_SOMEONE,
    REDEEM,
    REDEEM_SUBTEXT,
    REWARDS_AND_RECOGNITION,
    WELCOME_HEADING,
    WELCOME_SUBTEXT,
    WHATS_ON_YOUR_MIND,
    EMPLOYEE_AWARDS,
    EMPLOYEE_AWARDS_SUBTEXT,
    SEND_SUGGESTION,
    SEND_SUGGESTION_SUBTEXT,
} from './constants';
import './style.scss';
import Card from '../Card';

const ewWebUrl = process.env.REACT_APP_WEB_URL;

const Index = () => {
    const { darkTheme } = useCommonContext();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const isAdmin = searchParams.get('isAdmin') === 'true';
    const userCurrency = searchParams.get('userCurrency');
    const redeemBalance = searchParams.get('redeemBalance');
    const navigate = useNavigate();
    const recognitionSetupDone = searchParams.get('recognitionSetupDone') === 'true';
    const suggestionBoxEnabled = searchParams.get('suggestionBoxEnabled') === 'true';
    const setupRewardText = isAdmin ? RECOGNITION_SETUP_DONE_ADMIN : RECOGNITION_SETUP_DONE_USER;

    useEffect(() => {
        const decodedToken = jwt_decode(token);
        if (!decodedToken?.isActive) {
            navigate('/modals/inactive');
        }
    }, []);

    const cardData = [
        recognitionSetupDone && {
            Illustration: RecoginzeIllustration,
            headline: RECOGNIZE_SOMEONE,
            subtext: RECOGNIZE_SOMEONE_SUBTEXT,
            onClick: () => navigate('/modals/recognize', { state: { token, isTab: true } }),
        },
        {
            Illustration: RedeemIllustration,
            headline: REDEEM,
            externalLink: true,
            ExternalLinkIcon,
            subtext: REDEEM_SUBTEXT + userCurrency + ' ' + redeemBalance,
            link: `${ewWebUrl}dashboard/redemptions/redeem/list/?success=true&token=${token}`,
            isExternal: true,
        },
        suggestionBoxEnabled && {
            Illustration: SuggestionBoxIllustration,
            headline: SEND_SUGGESTION,
            subtext: SEND_SUGGESTION_SUBTEXT,
            onClick: () => navigate('/modals/suggestion-box', { state: { token, isTab: true } }),
        },
        isAdmin && {
            Illustration: BonusIllustration,
            headline: AWARD_BONUS,
            subtext: AWARD_BONUS_SUBTEXT,
            onClick: () => navigate('/modals/bonus', { state: { token, isTab: true } }),
        },
        isAdmin && {
            Illustration: EmployeeAwardsIllustration,
            headline: EMPLOYEE_AWARDS,
            externalLink: true,
            ExternalLinkIcon,
            subtext: <span dangerouslySetInnerHTML={{ __html: EMPLOYEE_AWARDS_SUBTEXT }} />,
            link: `${ewWebUrl}dashboard/employee-awards?success=true&token=${token}`,
            isExternal: true,
        },
    ].filter(Boolean);

    return (
        <div className={clsx({ 'choose-action': true, 'dark-choose-action': darkTheme })}>
            <div className='choose-action-heading-container'>
                <div className='headline-3 font-size-16 text-center'>{WELCOME_HEADING}</div>
                <div className='body-3 font-size-12 text-center'>{WELCOME_SUBTEXT}</div>
            </div>

            <div className='whats-on-mind'>
                <div className='headline-3'>{WHATS_ON_YOUR_MIND}</div>
                <div className='option-card-container'>
                    {cardData.map((cardProps, index) => (
                        <Card key={index} {...cardProps} />
                    ))}
                </div>
            </div>
            {!recognitionSetupDone && (
                <div className='setup-reward-container'>
                    <RewardsAndRecognitionIllustration />
                    <div>
                        <div className='subtitle-4'>{REWARDS_AND_RECOGNITION}</div>
                        <div className='body-3'>{setupRewardText}</div>
                        {isAdmin && (
                            <button
                                onClick={() =>
                                    app.openLink(`${ewWebUrl}dashboard/global-settings/?success=true&token=${token}`)
                                }
                            >
                                Setup
                            </button>
                        )}
                    </div>
                </div>
            )}
            {isAdmin && (
                <div className='text-center margin-top-16'>
                    <a
                        onClick={() =>
                            app.openLink(`${ewWebUrl}dashboard/global-settings/?success=true&token=${token}`)
                        }
                    >
                        {GO_TO_SETTINGS}
                    </a>
                </div>
            )}
        </div>
    );
};

export default Index;
