import React from 'react';
import { Routes as Switch, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import history from '../history';
import Tutorial from 'pages/tabs/Tutorial';
import ChooseAction from 'pages/modals/ChooseAction';
import Recognize from 'pages/modals/Recognize';
import Inactive from 'pages/modals/Inactive';
import AwardBonus from 'pages/modals/AwardBonus';
import AddCelebrationDates from 'pages/modals/AddCelebrationDates';
import Pulse from 'pages/modals/Pulse';
import ViewResponses from 'pages/modals/Pulse/ViewResponses';
import SuggestionBox from 'pages/modals/SuggestionBox';
import ReplyAnonymously from 'pages/modals/SuggestionBox/ReplyAnonymously';
import EditSuggestion from 'pages/modals/SuggestionBox/EditSuggestion';

const Index = () => (
    <Router history={history}>
        <Switch>
            <Route exact path='/tabs/tutorial' element={<Tutorial />} />
            <Route exact path='/modals/choose-action' element={<ChooseAction />} />
            <Route exact path='/modals/recognize' element={<Recognize />} />
            <Route exact path='/modals/addCelebrationDates' element={<AddCelebrationDates />} />
            <Route exact path='/modals/inactive' element={<Inactive />} />
            <Route exact path='/modals/bonus' element={<AwardBonus />} />
            <Route exact path='/modals/pulse/startAnswer' element={<Pulse />} />
            <Route exact path='/modals/pulse/viewResponse' element={<ViewResponses />} />
            <Route exact path='/modals/suggestion-box' element={<SuggestionBox />} />
            <Route exact path='/modals/suggestion-box/reply-anonymously' element={<ReplyAnonymously />} />
            <Route exact path='/modals/suggestion-box/edit' element={<EditSuggestion />} />
            <Route path='*' element={<Navigate replace to='/tabs/tutorial' />} />
        </Switch>
    </Router>
);

export default Index;
