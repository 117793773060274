import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSearchParams, useLocation } from 'react-router-dom';
import CustomLoader from 'components/CustomOverlay';
import clsx from 'clsx';
import QuestionComponent from './QuestionComponent';
import { EWButton } from 'components/EWButton';
import { usePulseData } from './usePulseData';
import { QUESTION_TYPES } from './constants';
import { dialog } from '@microsoft/teams-js';
import './style.scss';

const getValidationSchema = (questions) => {
    const schema = {};

    questions.forEach((question) => {
        if (question.required) {
            switch (question.type) {
                case QUESTION_TYPES.SINGLE_CHOICE:
                case QUESTION_TYPES.BINARY:
                case QUESTION_TYPES.RATING:
                case QUESTION_TYPES.RATING_5:
                case QUESTION_TYPES.LIKERT:
                    schema[question._id] = Yup.mixed()
                        .test(
                            'is-valid-type',
                            'This field is required',
                            (value) =>
                                value &&
                                (typeof value === 'object' || Array.isArray(value)) &&
                                (typeof value === 'object' || value.length > 0)
                        )
                        .required('This field is required');
                    break;

                case QUESTION_TYPES.MULTIPLE_CHOICE:
                    schema[question._id] = Yup.array()
                        .of(
                            Yup.mixed().test(
                                'is-string-or-object',
                                'Each option must be a string or an object',
                                (value) => typeof value === 'string' || typeof value === 'object'
                            )
                        )
                        .min(1, 'At least one option must be selected')
                        .required('At least one option must be selected');
                    break;

                case QUESTION_TYPES.SHORT_TEXT:
                    schema[question._id] = Yup.mixed()
                        .test(
                            'is-string-or-array-of-strings',
                            'This field must be a string or an array of strings with a maximum of 255 characters each',
                            (value) =>
                                typeof value === 'string' ||
                                (Array.isArray(value) &&
                                    value.every((item) => typeof item === 'string' && item.length <= 255))
                        )
                        .required('This field is required');
                    break;

                case QUESTION_TYPES.LONG_TEXT:
                    schema[question._id] = Yup.mixed()
                        .test(
                            'is-string-or-array-of-strings',
                            'This field must be a string or an array of strings with a maximum of 2500 characters each',
                            (value) =>
                                typeof value === 'string' ||
                                (Array.isArray(value) &&
                                    value.every((item) => typeof item === 'string' && item.length <= 2500))
                        )
                        .required('This field is required');
                    break;

                default:
                    break;
            }
        }
    });

    return Yup.object().shape(schema);
};

const QuestionForm = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const authToken = searchParams.get('token') || location.state?.token;
    const data = location.state?.data;
    const responseId = searchParams.get('responseId') || data?.responseId;
    const allowEdit = data?.allowEdit;
    const { pulseIssueData, responses, isLoading } = data || usePulseData(authToken, responseId);

    if (isLoading) {
        return <CustomLoader />;
    }

    const questions = pulseIssueData?.questions || [];

    const initialValues = questions.reduce((acc, curr) => {
        const response = responses.find((res) => res.question === curr._id);
        if (curr.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
            acc[curr._id] = response?.answer || [];
        } else {
            acc[curr._id] = response?.answer || '';
        }
        return acc;
    }, {});

    const extractAnswerValue = (answer) => {
        if (answer === '') {
            return [];
        }
        if (allowEdit) {
            if (Array.isArray(answer)) {
                return answer.map((a) => a.value || a);
            }
            return [answer.value || answer];
        }
        if (Array.isArray(answer)) {
            return answer.map((a) => a.value);
        }
        return [answer.value || answer];
    };

    const handleSubmit = (values) => {
        const answers = questions.map((question) => ({
            quesId: question._id,
            value: extractAnswerValue(values[question._id]),
        }));

        dialog.url.submit(
            {
                data: {
                    responseId,
                    answerData: answers,
                    isLastPage: true,
                },
                flagType: 'pulse_answerSubmit',
            },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <div className={clsx({ 'pulse-modal': true })}>
            <Formik
                initialValues={initialValues}
                validationSchema={getValidationSchema(questions)}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <h2>{pulseIssueData.name}</h2>
                        <div className='separator-line' />
                        <div className='questions-wrapper'>
                            {questions.map((question, index) => (
                                <QuestionComponent
                                    key={question._id}
                                    question={question}
                                    questionNumber={index + 1}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            ))}
                        </div>
                        <div className='sticky-questions-footer'>
                            <div className='footer-questions-content'>
                                <EWButton appearance='primary' buttonText='Submit' type='button' />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default QuestionForm;
