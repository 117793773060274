import * as Yup from 'yup';

export const FORM_FIELDS = {
    suggestionMessage: {
        name: 'suggestionMessage',
        label: 'Your suggestion',
        placeholder: 'Enter your suggestion here.',
    },
    anonymousConfig: {
        name: 'anonymousConfig',
        label: 'Submit anonymously',
        subtitle: 'Your name will remain private.',
    },
    postPubliclyConfig: {
        name: 'postPubliclyConfig',
        label: 'Submit publicly',
        subtitle: 'Your suggestion will be posted in ',
    },
    suggestionBoxTitle:
        'Start a discussion on policies, culture,  leadership and more. Drive change with a suggestion.',
};

export const initialValues = {
    suggestionMessage: '',
    anonymousConfig: true,
    postPubliclyConfig: false,
};

export const REPLY_ANONYMOUSLY = {
    replyMessageForm: {
        label: '',
        name: 'replyMessage',
        placeholder: 'Enter your reply here',
    },
    initialValues: { replyMessage: '' },
    footerMessage: 'Your reply will be posted anonymously in the thread',
};

export const EDIT_SUGGESTION = {
    editForm: {
        label: 'Your suggestion',
        name: 'editSuggestion',
        placeholder: 'Enter your suggestion here.',
    },
    headerMessage: 'Start a discussion on policies, culture,  leadership and more. Drive change with a suggestion.',
};

export const getValidationSchemaForSuggestionUpdate = () =>
    Yup.object().shape({
        editSuggestion: Yup.string()
            .required('Suggestion message is required')
            .max(3000, 'Suggestion message must be less than or equal to 3000 characters'),
    });

export const getValidationSchemaForReply = () =>
    Yup.object().shape({
        replyMessage: Yup.string()
            .required('Reply message is required')
            .max(3000, 'Reply message must be less than or equal to 3000 characters'),
    });

export const getValidationSchema = () =>
    Yup.object().shape({
        suggestionMessage: Yup.string()
            .required('Suggestion message is required')
            .max(3000, 'Suggestion message must be less than or equal to 3000 characters'),
    });
