import { isFuture, differenceInMinutes } from 'date-fns';

export const capitalizeFLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};

export const getTeamsChannelName = (channel, isDefaultChannel = false) => {
    let groupName = channel?.groupName;
    let channelName = isDefaultChannel ? channel.name : channel.channelName;
    if (channelName) {
        return `${groupName} \u2192 ${channelName}`;
    }
    return 'Select';
};

export const showSnackBarMessage = (SetSnackbar, variant, message) => {
    SetSnackbar({
        open: true,
        variant,
        message: message,
    });
};

export const clockTimeWithMinInterval = (interval, date) => {
    const timeArray = [],
        periods = ['AM', 'PM'],
        hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const currentTime = new Date();
    currentTime.setSeconds(0, 0);
    for (const prop in periods) {
        for (const hour in hours) {
            for (let min = 0; min < 60; min += interval) {
                let hrs = hours[hour];
                let time = ('0' + hrs).slice(-2) + ':' + ('0' + min).slice(-2) + ' ' + periods[prop];
                if (periods[prop] === 'AM' && hrs === 12) hrs = 0;
                if (periods[prop] === 'PM' && hrs < 12) hrs += 12;
                date.setHours(hrs, min, 0, 0);
                isFuture(date) && differenceInMinutes(date, currentTime) > 15 && timeArray.push(time);
            }
        }
    }
    return timeArray;
};

export const timeInterval = (date, addMinutes = false) => {
    const timeIntervalOptions = clockTimeWithMinInterval(15, date)?.map((slot, index) => {
        const option = {
            header: slot,
            id: index + 1,
            ...(addMinutes && { minutes: getTime(slot) }),
        };
        return option;
    });
    /* The above code is checking if the current date is the same as the date stored in the "date"
   variable. If they are the same, it adds an option to the beginning of the "timeIntervalOptions"
   array with a label of "Send instantly" and a value of 0. */
    if (date.getDate() === new Date().getDate()) {
        timeIntervalOptions.unshift({ header: 'Send instantly', id: 0 });
    }
    return timeIntervalOptions;
};

export const getLaunchDate = (date, time, sendInstantly) => {
    if (sendInstantly) {
        return { launchDate: time };
    }
    const dateStr = new Date(date);
    const timeStr = time;

    // Parse the time string
    const [timer, meridian] = timeStr.split(' ');
    let [hour, minute] = timer.split(':');

    if (hour === '12') {
        hour = '00';
    }
    if (meridian === 'PM') {
        hour = parseInt(hour, 10) + 12;
    }

    // Set the time in the date object
    dateStr.setHours(hour);
    dateStr.setMinutes(minute);

    // Format the combined date and time into a string in ISO format
    const combinedDateString = dateStr.toISOString();
    return { launchDate: combinedDateString };
};

export const addSecondsToCurrentDate = (seconds) => {
    let currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + seconds);
    return currentDate;
};
