import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { dialog } from '@microsoft/teams-js';
import { useSearchParams } from 'react-router-dom';
import { Body1, Button, typographyStyles, makeStyles } from '@fluentui/react-components';
import CustomLoader from 'components/CustomOverlay';
import { CustomTextArea } from 'components/CustomTextArea';
import { EWButton } from 'components/EWButton';
import { REPLY_ANONYMOUSLY, getValidationSchemaForReply } from '../constants';
import './style.scss';

const useStyles = makeStyles({
    text: typographyStyles.body1,
});

const ReplyAnonymously = () => {
    const styles = useStyles();
    const [searchParams] = useSearchParams();
    const suggestionId = searchParams.get('suggestionId');
    const [submitLoader, setSubmitLoader] = useState(false);

    const handleSubmit = (values) => {
        let data = {
            message: values.replyMessage,
            suggestionId: suggestionId,
        };

        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'messagingExtension', flagType: 'suggestionbox_submitReply' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <CustomLoader active={submitLoader}>
            <div className='reply-anonymous-container'>
                <div className='headline-2'>Reply Anonymously</div>
                <Formik
                    initialValues={REPLY_ANONYMOUSLY.initialValues}
                    validationSchema={getValidationSchemaForReply()}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <Form>
                        <CustomTextArea {...REPLY_ANONYMOUSLY.replyMessageForm} />
                        <div className='footer-text'>
                            <Body1>{REPLY_ANONYMOUSLY.footerMessage}</Body1>
                        </div>
                        <div className='reply-button-container'>
                            <div className='close-button-container'>
                                <Button
                                    appearance='default'
                                    onClick={() => dialog.url.submit()}
                                    className={styles.text}
                                >
                                    Close
                                </Button>
                            </div>

                            <EWButton appearance='primary' buttonText='Reply Anonymously' />
                        </div>
                    </Form>
                </Formik>
            </div>
        </CustomLoader>
    );
};

export default ReplyAnonymously;
