import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { dialog } from '@microsoft/teams-js';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Body1, Button, typographyStyles, makeStyles } from '@fluentui/react-components';
import CustomLoader from 'components/CustomOverlay';
import { CustomTextArea } from 'components/CustomTextArea';
import { EWButton } from 'components/EWButton';
import AIEnhanceButton from 'components/AIEnhanceButton';
import { EDIT_SUGGESTION, getValidationSchemaForSuggestionUpdate } from '../constants';
import './style.scss';

const useStyles = makeStyles({
    text: typographyStyles.body1,
});

const EditSuggestion = () => {
    const styles = useStyles();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') || location.state?.token;
    const suggestionId = searchParams.get('suggestionId');
    const message = searchParams.get('message');
    const isAiEnabled = searchParams.get('isAiEnabled') === 'true';
    const [submitLoader, setSubmitLoader] = useState(false);
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);

    const initialValues = {
        editSuggestion: message,
    };

    const handleSubmit = (values) => {
        let data = {
            message: values.editSuggestion,
            suggestionId: suggestionId,
            isAiEnhanced,
        };

        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'messagingExtension', flagType: 'suggestionbox_update' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <CustomLoader active={submitLoader}>
            <div className='edit-suggestion-container'>
                <div className='headline-2'>Edit Suggestion</div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={getValidationSchemaForSuggestionUpdate()}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className='header-text'>
                                <Body1>{EDIT_SUGGESTION.headerMessage}</Body1>
                            </div>
                            <CustomTextArea
                                {...EDIT_SUGGESTION.editForm}
                                label={
                                    <div className='text-area-label'>
                                        {EDIT_SUGGESTION.editForm.label}
                                        {isAiEnabled && (
                                            <AIEnhanceButton
                                                reason={values.editSuggestion}
                                                setReason={(reason) => {
                                                    setFieldValue('editSuggestion', reason);
                                                    setIsAiEnhanced(true);
                                                }}
                                                authToken={token}
                                            />
                                        )}
                                    </div>
                                }
                            />
                            <div className='footer-button-container'>
                                <div className='close-button-container'>
                                    <Button
                                        appearance='default'
                                        onClick={() => dialog.url.submit()}
                                        className={styles.text}
                                    >
                                        Close
                                    </Button>
                                </div>

                                <EWButton appearance='primary' buttonText='Send Suggestion' />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </CustomLoader>
    );
};

export default EditSuggestion;
