import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from '@fluentui/react-components';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import './style.scss';

const CustomImageSelect = ({ images, selectedImage, setSelectedImage }) => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleImageSelection = (image) => {
        setSelectedImage(image);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='custom-image-select' ref={dropdownRef}>
            <Button
                appearance='default'
                onClick={() => setOpen(!open)}
                disabled={images.length === 0}
                className={`image-select-button ${open ? 'open' : ''}`}
            >
                Choose GIF
                <span className={`dropdown-arrow ${open ? 'rotated' : ''}`}>
                    <CloseIcon />
                </span>
            </Button>
            {images.length > 0 && open && (
                <div className='image-dropdown'>
                    <div className='image-grid'>
                        {images.map((image) => (
                            <div
                                key={image}
                                className={`image-item ${image === selectedImage ? 'selected' : ''}`}
                                onClick={() => handleImageSelection(image)}
                            >
                                <Image
                                    src={image}
                                    alt='recognition gif'
                                    shape='rounded'
                                    height={100}
                                    width={100}
                                    className='image-element'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

CustomImageSelect.propTypes = {
    images: PropTypes.array.isRequired,
    selectedImage: PropTypes.string.isRequired,
    setSelectedImage: PropTypes.func.isRequired,
};

export default CustomImageSelect;
