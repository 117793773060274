import React, { useEffect, useState } from 'react';
import {
    Combobox,
    Option,
    Field as FluentField,
    useId,
    Spinner,
    makeStyles,
    typographyStyles,
} from '@fluentui/react-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    listbox: {
        maxHeight: '50vh',
    },
    text: typographyStyles.subtitle2,
});

export const CustomSingleSelectDropdown = ({
    label,
    placeholder,
    dropdownOption,
    isDropdownOptionLoading,
    name,
    render = true,
    onChange,
}) => {
    const styles = useStyles();
    const comboId = useId('combo-box');

    const [matchingOptions, setMatchingOptions] = useState(dropdownOption);

    useEffect(() => {
        setMatchingOptions(dropdownOption);
    }, [dropdownOption]);

    if (!render) {
        return null;
    }

    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue, setFieldTouched, errors, touched } }) => {
                const onOptionSelect = (ev, data) => {
                    setFieldValue(name, data.selectedOptions[0]);
                    if (onChange) {
                        onChange(data.selectedOptions[0]);
                    }
                };
                const onInput = (ev) => {
                    const value = ev.target.value.trim();
                    const matches = dropdownOption.filter(
                        (option) => option['header'].toLowerCase().indexOf(value.toLowerCase()) === 0
                    );
                    setMatchingOptions(matches);
                    setFieldValue(name, { ...value, header: ev.target.value });
                };
                const handleBlur = () => {
                    if (value?.length > 0) {
                        return;
                    }
                    const isValid = dropdownOption.some((option) => option.header === value?.header);
                    setFieldTouched(name, true);
                    if (!isValid) {
                        setFieldValue(name, '');
                    }
                };

                return (
                    <FluentField
                        id={comboId}
                        label={label}
                        className={styles.text}
                        style={{ maxWidth: 800, marginBottom: '10px' }}
                        validationMessage={touched[name] && errors[name]}
                    >
                        <Combobox
                            aria-labelledby={comboId}
                            placeholder={placeholder}
                            onBlur={handleBlur}
                            onOptionSelect={onOptionSelect}
                            value={Array.isArray(value) ? value[0] : value?.header || ''}
                            onInput={onInput}
                            listbox={{ className: styles.listbox }}
                        >
                            {isDropdownOptionLoading ? (
                                <Spinner />
                            ) : (
                                matchingOptions.map((option) => (
                                    <Option key={option.id} text={option.header} value={option}>
                                        {option.header}
                                    </Option>
                                ))
                            )}
                        </Combobox>
                    </FluentField>
                );
            }}
        </Field>
    );
};

CustomSingleSelectDropdown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.string,
    dropdownOption: PropTypes.array,
    isDropdownOptionLoading: PropTypes.bool,
    render: PropTypes.bool,
    onChange: PropTypes.func,
};

CustomSingleSelectDropdown.defaultProps = {
    onChange: null,
};
