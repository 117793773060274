import { useSearchParams, useNavigate } from 'react-router-dom';
import CustomLoader from 'components/CustomOverlay';
import { Button, typographyStyles, makeStyles } from '@fluentui/react-components';
import { usePulseData } from '../usePulseData';
import './style.scss';

const useStyles = makeStyles({
    text: typographyStyles.body1,
});

const ViewResponses = () => {
    const [searchParams] = useSearchParams();
    const styles = useStyles();
    const navigate = useNavigate();
    const authToken = searchParams.get('token') || location.state?.token;
    const responseId = searchParams.get('responseId');
    const allowEdit = searchParams.get('allowEdit') === 'true';
    const { pulseIssueData, responses, isLoading } = usePulseData(authToken, responseId);

    if (isLoading) {
        return <CustomLoader />;
    }

    const questions = pulseIssueData?.questions || [];

    const extractAnswerValue = (answer) => {
        if (Array.isArray(answer)) {
            return answer.map((a) => a).join(', ');
        }
        return answer.value || answer;
    };

    return (
        <div className='responses-modal'>
            <h2>{pulseIssueData.name}</h2>
            <div className='separator-line' />
            <div className='responses-container'>
                {responses.map((response, index) => {
                    // Find the matching question from pulseIssueData.questions
                    const matchingQuestion = questions.find((question) => question._id === response.question);

                    return (
                        <div key={response.question}>
                            {/* Display the question title instead of response.question */}
                            <strong>{`Q${index + 1}: ${matchingQuestion.title}`}</strong>
                            <p>
                                Your Response: <strong>{extractAnswerValue(response.answer) || 'Skipped'}</strong>
                            </p>
                        </div>
                    );
                })}
            </div>
            {allowEdit && (
                <div className='sticky-responses-footer'>
                    <div className='footer-responses-content'>
                        <Button
                            appearance='primary'
                            className={styles.text}
                            onClick={() =>
                                navigate('/modals/pulse/startAnswer', {
                                    state: {
                                        data: {
                                            responseId,
                                            allowEdit,
                                            pulseIssueData,
                                            responses,
                                            isLoading,
                                        },
                                    },
                                })
                            }
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewResponses;
