import React from 'react';
import { Formik, Form } from 'formik';
import { dialog } from '@microsoft/teams-js';
import { Body1Stronger, Checkbox } from '@fluentui/react-components';
import { CustomTextArea } from 'components/CustomTextArea';
import AIEnhanceButton from 'components/AIEnhanceButton';
import { useSuggestionBoxData } from './useSuggestionBoxData';
import { getValidationSchema, FORM_FIELDS, initialValues } from './constants';
import { EWButton } from 'components/EWButton';
import './style.scss';

const SuggestionBoxForm = ({ authToken, setSubmitLoader }) => {
    const { allowAnonymous, postPublicly, suggestionChannel, isAiEnabled } = useSuggestionBoxData(authToken);

    const handleSubmit = (values) => {
        let data = {
            message: values.suggestionMessage,
            anonymous: allowAnonymous && values.anonymousConfig,
            postPublicly: postPublicly && values.postPubliclyConfig,
        };

        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'messagingExtension', flagType: 'suggestionbox_submit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='suggestion-box-title'>{FORM_FIELDS.suggestionBoxTitle}</div>
                    <CustomTextArea
                        {...FORM_FIELDS.suggestionMessage}
                        label={
                            <div className='text-area-label'>
                                {FORM_FIELDS.suggestionMessage.label}
                                {isAiEnabled && (
                                    <AIEnhanceButton
                                        reason={values.suggestionMessage}
                                        setReason={(reason) => setFieldValue('suggestionMessage', reason)}
                                        authToken={authToken}
                                    />
                                )}
                            </div>
                        }
                    />
                    {(allowAnonymous || postPublicly) && (
                        <div className='suggestion-box-config'>
                            <Body1Stronger>Configurations</Body1Stronger>
                            {allowAnonymous && (
                                <Checkbox
                                    label={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>{FORM_FIELDS.anonymousConfig.label}</span>
                                            <span style={{ fontSize: 'small', color: 'gray' }}>
                                                {FORM_FIELDS.anonymousConfig.subtitle}
                                            </span>
                                        </div>
                                    }
                                    checked={values.anonymousConfig}
                                    onChange={(e) => setFieldValue('anonymousConfig', e.target.checked)}
                                />
                            )}
                            {postPublicly && (
                                <Checkbox
                                    label={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>{FORM_FIELDS.postPubliclyConfig.label}</span>
                                            <span style={{ fontSize: 'small', color: 'gray' }}>
                                                {FORM_FIELDS.postPubliclyConfig.subtitle} {suggestionChannel}.
                                            </span>
                                        </div>
                                    }
                                    checked={values.postPubliclyConfig}
                                    onChange={(e) => setFieldValue('postPubliclyConfig', e.target.checked)}
                                />
                            )}
                        </div>
                    )}

                    <EWButton appearance='primary' buttonText='Submit' />
                </Form>
            )}
        </Formik>
    );
};

export default SuggestionBoxForm;
