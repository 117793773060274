import React from 'react';
import PropTypes from 'prop-types';
import { Body1Stronger, Caption1, Image } from '@fluentui/react-components';
import CustomImageSelect from 'components/CustomImageSelect';
import './style.scss';

const SelectGifSection = ({ images, selectedImage, setSelectedImage }) => {
    return (
        <div className='select-gif-container'>
            <div className='select-gif-body'>
                <div className='gif-label-container'>
                    <Body1Stronger>Select GIF</Body1Stronger>
                    <Caption1>(Optional)</Caption1>
                </div>
                <CustomImageSelect images={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
            </div>
            {selectedImage && (
                <div className='selected-image-container'>
                    <Image
                        src={selectedImage}
                        alt='recognition gif'
                        shape='rounded'
                        height={100}
                        width={100}
                        className='image-select'
                    />
                    <div className='close-icon' onClick={() => setSelectedImage('')}>
                        &#10005;
                    </div>
                </div>
            )}
        </div>
    );
};

SelectGifSection.propTypes = {
    images: PropTypes.array.isRequired,
    selectedImage: PropTypes.string.isRequired,
    setSelectedImage: PropTypes.func.isRequired,
};

export default SelectGifSection;
