import React from 'react';
import { dialog } from '@microsoft/teams-js';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { EWButton } from 'components/EWButton';
import { CustomCalendar } from 'components/CustomCalender';
import { Caption1Strong } from '@fluentui/react-components';
import { initialValues, validationSchema, FORM_FIELDS, SUB_HEADER } from './constants';

const CelebrationDatesForm = ({ birthDate, anniversaryDate, setSubmitLoader }) => {
    const handleSubmit = (values) => {
        const data = {
            birthDate: birthDate ? null : values.birthDate,
            anniversaryDate: anniversaryDate ? null : values.workAnnivarsaryDate,
        };
        setSubmitLoader(true);
        dialog.url.submit(
            {
                data: data,
                startedVia: 'messagingExtension',
                flagType: 'celebrations_datesModalSubmit',
            },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues(birthDate, anniversaryDate)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values }) => (
                <Form>
                    <div className='subheader-wrapper'>
                        <Caption1Strong className='subheader'>{SUB_HEADER}</Caption1Strong>
                        🥳
                    </div>

                    <CustomCalendar {...FORM_FIELDS.birthDate} disabled={birthDate} maxDate={new Date()} />
                    <CustomCalendar
                        {...FORM_FIELDS.workAnnivarsaryDate}
                        disabled={anniversaryDate}
                        maxDate={new Date()}
                    />
                    <EWButton
                        appearance='primary'
                        buttonText='Save dates'
                        disabled={
                            (birthDate && anniversaryDate) || !(values['birthDate'] || values['workAnnivarsaryDate'])
                        }
                    />
                </Form>
            )}
        </Formik>
    );
};
CelebrationDatesForm.propTypes = {
    birthDate: PropTypes.string,
    anniversaryDate: PropTypes.string,
    setSubmitLoader: PropTypes.func,
};

export default CelebrationDatesForm;
